.totop {
  background-color: $mainColor;
  color: $backgroundNavbar;
  position: fixed;
  z-index: 1;
  right: 0;
  bottom: 0;
  margin: 0.5rem;
  border-radius: 50px;
  width: 45px;
  height: 45px;
  text-align: center;

  &:hover {
    cursor: pointer;
    transform: translateY(-10px);
    transition: 200ms linear all;
  }

  &__arrow {
    font-size: 2rem;
  }
}
