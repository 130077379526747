.container-NotFound {
  border: 1px solid #f1f1f5;
  box-sizing: border-box;
  border-radius: 6px;
  background: #ffffff;
  padding: 1.2rem;
  &__image {
    display: flex;
    justify-content: center;
  }
  &__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    &__header {
      color: $grayDark;
      font-weight: bold;
      font-size: 2rem;
      margin-top: 1rem;
    }
    &__para {
      color: $grayLight;
    }
    &__btn {
      width: 30%;
      height: 2.6rem;
    }
  }
}
@media only screen and (max-width: 412px) {
  .container-NotFound {
    &__image {
      width: 100%;
    }
    &__content {
      &__btn {
        width: 50%;
      }
    }
  }
}
