.drag-image {
  background-color: $mainColor;
  color: $backgroundNavbar;
  border: 1px solid transparent;
  height: 2.5rem;
  margin-top: 0.5rem;
  &:focus {
    background-color: $mainColor;
    color: $backgroundNavbar;
    border: 1px solid transparent;
  }
  &:hover {
    background-color: $mainColor;
    color: $backgroundNavbar;
    border: 1px solid transparent;
  }
}
.ant-upload-list-item {
  width: 30%;
  border-radius: 10px !important;
  height: 3rem !important;
}
.newDrag .ant-upload-list-item {
  width: 100%;
  border-radius: 10px !important;
  height: 3rem !important;
}
