// .messageError .ant-form-item-explain-error {
//   margin-top: -0.5rem !important;
// }
// .ant-alert {
//   margin: 4px auto !important;
//   border-radius: 10px;
//   height: 2.7rem;
// }
// .login {
//   background: #e5e5e5;
//   min-height: 900px;

//   &__row {
//     display: flex;

//     &__image {
//       display: flex;
//       flex-direction: column;
//       flex: 0 0 50%;
//       max-width: 50%;
//       margin-top: 3rem;
//     }

//     &__box {
//       h2 {
//         color: $grayDark;
//         font-weight: bold;
//         margin-top: 3rem;
//       }
//       p {
//         color: $grayLight;
//       }
//       position: relative;
//       padding: 4rem;
//       margin: 2.4rem;
//       background: $backgroundNavbar;
//       border-radius: 6px;
//       width: 35%;
//       height: 600px;
//       margin-top: 7rem;
//       .ant-checkbox + span {
//         color: $grayDark;
//         font-weight: bold;
//         margin-top: 0.7rem;
//       }
//       .btn-login {
//         width: 100%;
//         color: #000000;
//         height: 2.9rem;
//       }

//       .ant-form-item-label {
//         margin-bottom: 0.5rem;
//       }
//     }
//   }
// }

// @media (max-width: 768px) {
//   .login {
//     &__row {
//       &__image {
//         flex: 0 0 100%;
//         max-width: 100%;
//       }
//     }
//   }
// }
// @media (max-width: 768px) {
//   .login {
//     &__row {
//       &__image {
//         display: none;
//       }
//       &__box {
//         width: 100%;
//       }
//     }
//   }
// }

// .login-container {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   width: 100%;
//   background-color: #000000;
//   height: 100vh;
//   .login_image {
//     width: 40%;
//     height: 100vh;
//     position: relative;
//     img {
//       position: absolute;
//       bottom: 1rem;
//       left: 0px;
//       width: 100%;
//     }
//   }
//   &__form {
//     width: 40%;
//     border-radius: 15px;
//     padding: 1rem 2rem;
//     h3 {
//       color: white;
//       font-size: 40px;
//     }
//     Input {
//       height: 2.2rem;
//     }
//     Button {
//       width: 50%;
//       height: 3rem;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       margin: 0 auto;
//       margin-top: 1rem;
//     }

//     .ant-form-vertical .ant-form-item-label > label {
//       margin-bottom: 0.5rem;
//       color: white;
//     }
//     .ant-btn:hover {
//       background: white;
//       color: #000000;
//       border: 1px solid white;
//     }
//     .ant-form {
//       padding-top: 20px;
//     }
//   }
// }
// @media (max-width: 1150px) {
//   .login-container {
//     justify-content: center;
//     &__form {
//       width: 45%;
//     }
//     .login_image {
//       width: 45%;
//     }
//   }
// }

// @media (max-width: 768px) {
//   .login-container {
//     justify-content: center;
//     &__form {
//       width: 80%;
//       padding: 1rem;
//       h3 {
//         color: white;
//         font-size: 30px;
//       }
//     }
//     .login_image {
//       display: none;
//     }
//   }
// }
.messageError .ant-form-item-explain-error {
  margin-top: -0.5rem !important;
}
.ant-alert {
  margin: 4px auto !important;
  border-radius: 10px;
  height: 2.7rem;
}
.login {
  background: #e5e5e5;
  min-height: 900px;

  &__row {
    display: flex;

    &__image {
      display: flex;
      flex-direction: column;
      flex: 0 0 50%;
      max-width: 50%;
      margin-top: 3rem;
    }

    &__box {
      h2 {
        color: $grayDark;
        font-weight: bold;
        margin-top: 3rem;
      }
      p {
        color: $grayLight;
      }
      position: relative;
      padding: 4rem;
      margin: 2.4rem;
      background: $backgroundNavbar;
      border-radius: 6px;
      width: 35%;
      height: 600px;
      margin-top: 7rem;
      .ant-checkbox + span {
        color: $grayDark;
        font-weight: bold;
        margin-top: 0.7rem;
      }
      .btn-login {
        width: 100%;
        color: #000000;
        height: 2.9rem;
      }

      .ant-form-item-label {
        margin-bottom: 0.5rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .login {
    &__row {
      &__image {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}
@media (max-width: 768px) {
  .login {
    &__row {
      &__image {
        display: none;
      }
      &__box {
        width: 100%;
      }
    }
  }
}

.login-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-image: url("../../images/backgroundLogin.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100vh;
  .login_image {
    width: 40%;
    height: 100vh;
    position: relative;
    img {
      position: absolute;
      bottom: 0rem;
      right: 0px;
      width: 25%;
    }
  }
  &__form {
    width: 40%;
    border-radius: 15px;
    padding: 1rem 2rem;
    margin-top: 15rem;
    h3 {
      color: white;
      font-size: 40px;
    }
    Input {
      height: 2.5rem;
    }
    Button {
      width: 50%;
      // background: rgb(0, 0, 0, 0.2);
      background: rgb(255, 255, 255, 0.2);
      color: #ffffff;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      margin-top: 1rem;
    }

    .ant-form-vertical .ant-form-item-label > label {
      margin-bottom: 0.5rem;
      color: white;
    }
    .ant-btn:hover {
      // background: rgb(0, 0, 0, 0.2);
      background: rgb(255, 255, 255, 0.2);

      color: #ffffff;
      border: 1px solid transparent;
    }
    .ant-form {
      padding-top: 20px;
    }
    .antInput.ant-input:focus,
    .antInput.ant-input-focused {
      border-color: #ffffff !important;
    }
    .antInput.ant-input:hover {
      border-color: #ffffff !important;
    }
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: #ffffff !important;
    }
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
      border-color: #ffffff !important;
    }
    .antInput.ant-input::placeholder {
      color: #ffffff !important;
    }
    .passwardInput .input::placeholder {
      color: #ffffff !important;
    }
    .ant-input-password-icon.anticon {
      color: white !important;
    }
    .ant-input-status-error:not(.ant-input-disabled):not(
        .ant-input-borderless
      ).ant-input:hover {
      background: none !important;
      color: #ffffff !important;
    }

    .ant-input {
      background: transparent;
      border-color: rgb(255, 255, 255, 0.2);
      color: white;
    }

    .ant-input::placeholder {
      color: white;
      font-weight: bold;
    }
    .ant-input-affix-wrapper {
      // background-color: rgb(255, 255, 255, 0.2) !important;
      background-color: rgb(0, 0, 0, 0.2) !important;
      border-color: rgb(255, 255, 255, 0.2) !important;
    }
    .ant-input-status-error:not(.ant-input-disabled):not(
        .ant-input-borderless
      ).ant-input,
    .ant-input-status-error:not(.ant-input-disabled):not(
        .ant-input-borderless
      ).ant-input:hover {
      background-color: rgb(255, 255, 255, 0.2) !important;
    }
    .ant-input:-webkit-autofill,
    .ant-input:-webkit-autofill:hover,
    .ant-input:-webkit-autofill:focus,
    .ant-input:-webkit-autofill:active {
      -webkit-box-shadow: none !important;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: white !important;
    }
    .ant-form-item-explain-error {
      color: #ff4cad;
    }
    .ant-input-affix-wrapper-status-error .ant-input-feedback-icon {
      color: #ff4cad;
    }
    .ant-input-affix-wrapper-status-error:not(
        .ant-input-affix-wrapper-disabled
      ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-status-error:not(
        .ant-input-affix-wrapper-disabled
      ):not(
        .ant-input-affix-wrapper-borderless
      ).ant-input-affix-wrapper-focused {
      box-shadow: none;
    }
  }
  &__errorAlert {
    border-radius: 8px;
    width: 60%;
    background: #ff4cad;
    color: #ffffff;
    font-weight: bold;
    padding: 0.8rem 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .close-icon {
      background: #fff;
      color: #ff4cad;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }
}
@media (max-width: 1150px) {
  .login-container {
    justify-content: center;
    &__form {
      width: 45%;
    }
    .login_image {
      width: 45%;
    }
    &__errorAlert {
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .login-container {
    justify-content: center;
    &__form {
      width: 80%;
      padding: 1rem;
      h3 {
        color: white;
        font-size: 30px;
      }
    }
    &__errorAlert {
      width: 100%;
    }
    .login_image {
      display: none;
    }
  }
}
