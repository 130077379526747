.modal-business .ant-modal-title {
  display: flex;
  justify-content: center;
  font-weight: bold;
}
.ant-modal .modal-business-Detail {
  width: 800px !important;
}
.modal-business .ant-modal-content {
  border: 1px solid transparent;
  border-radius: 25px;
}
.modal-business-Detail .ant-modal-content {
  border: 1px solid transparent;
  border-radius: 25px;
}
.modal-business-Detail .ant-modal-content {
  width: 62rem;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.modal-business .ant-modal-header {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.modal-business-Detail .ant-modal-header {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.modal-above {
  display: flex;

  justify-content: space-between;
  border: 1px solid gainsboro;
  align-items: center;
  padding: 0rem 1rem;

  border-radius: 15px;
  // flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

.column1,
.column2,
.column4 {
  width: 33%;
  &__column {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    &__header {
      font-weight: bold;
      color: $grayDark;
      margin-top: 0.5rem;
      font-size: 14px;
      margin-bottom: 0.3rem;
    }
    &__footer {
      color: $grayLight;
      margin-bottom: 1.5rem;
    }
  }
  &__btn-export {
    width: 39%;
    height: 2.6rem;
    margin-top: 1.5rem;
    border: 1px solid transparent;
    border-radius: 25px;
    &:hover {
      background-color: $mainColor;
      color: $backgroundNavbar;
    }
  }
  &__btn-print {
    width: 37%;
    height: 2.6rem;
    margin-top: 1.5rem;
    border: 1px solid transparent;
    border-radius: 25px;
    background-color: $greenColor;
    color: $backgroundNavbar;

    &:hover {
      background-color: $greenColor;
      color: $backgroundNavbar;
      border: 1px solid transparent;
    }
    &:focus {
      background-color: $greenColor;
      color: $backgroundNavbar;
      border: 1px solid transparent;
    }
  }
}

.column2 {
  &__column {
    display: flex;
    flex-direction: column;
    &__header {
      font-weight: bold;
      color: $grayDark;
      margin-top: 0.5rem;
      font-size: 14px;
      margin-bottom: 0.3rem;
    }
    &__footer {
      color: $grayLight;
    }
  }
  &__btn-export {
    width: 39%;
    height: 2.6rem;
    margin-top: 1.5rem;
    border: 1px solid transparent;
    border-radius: 25px;
    &:hover {
      background-color: $mainColor;
      color: $backgroundNavbar;
    }
  }
  &__btn-print {
    width: 37%;
    height: 2.6rem;
    margin-top: 1.5rem;
    border: 1px solid transparent;
    border-radius: 25px;
    background-color: $greenColor;
    color: $backgroundNavbar;

    &:hover {
      background-color: $greenColor;
      color: $backgroundNavbar;
      border: 1px solid transparent;
    }
    &:focus {
      background-color: $greenColor;
      color: $backgroundNavbar;
      border: 1px solid transparent;
    }
  }
}
.column3 {
  display: flex;

  justify-content: space-around;
}
.btn-icon {
  margin-left: 0.5rem;
}
.btn-icon:hover {
  color: black;
  background-color: #f5f5f5;
}
.btn-icon:focus {
  color: black;
  background-color: #f5f5f5;
}
// .transaction-image {
//   width: 40%;
//   border: 1px solid transparent;
//   border-radius: 50%;
//   height: 28%;
// }
// .transaction-user {
//   font-weight: bold;
//   font-size: 15px;
//   color: $grayDark;
//   margin-left: -9rem;
// }
.space-transaction {
  margin-bottom: 1.5rem !important;
}
.modal-business-Detail .ant-modal-title {
  display: flex;
  justify-content: center;
  font-weight: bold;
}
.btn-edit {
  display: grid;
  margin-top: 0.5rem;
  width: 72%;
}
.container {
  &__topheader {
    display: flex;
    justify-content: space-between;
  }
}

.profile__form .inputs-business.ant-input {
  width: 100%;
  height: 2.7rem;
}

.header {
  display: flex;
}
.header .ant-form-item-control {
  margin-top: 3.4rem;
  margin-left: 1rem;
}
.qrCode {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__image {
    width: 15rem;
  }
  &__header {
    color: $mainColor;
    font-weight: 400;
  }
}
.btnHover:hover {
  background-color: $mainColor;
  color: white;
}
.trans-doc {
  display: flex;
}
.doc {
  width: 34%;
}
.trans {
  margin-right: 2rem;
  width: 65%;
}
.headerColumn4 {
  margin-top: -2rem;
}
@media (max-width: 700px) {
  .column1,
  .column2,
  .column4 {
    width: 100%;
  }
  .trans-doc {
    display: block;
    flex-wrap: wrap;
  }
  .doc {
    width: 100%;
  }
  .trans {
    width: 100%;
  }
  .headerColumn4 {
    margin-top: unset;
  }
  .col4 {
    display: none;
  }
  .col2 {
    display: none;
  }
}
