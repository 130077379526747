.MultiLineContainer {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  min-width: 100%;
  min-height: 540px;
}
.MultiLineContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.BaseBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.BaseBetweenCloumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.WraperLeft {
  display: flex;
  flex-direction: column;
}
.Header {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #27272e;
}
.Content span {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.1px;
  color: #696974;
}
.Numbers span {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
}
.WrapeRight span {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #808191;
  margin-left: 15px;
}
.circle {
  width: 12px;
  height: 12px;
  border-radius: 6px;
}

.filter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;
  height: 34px;
  border-radius: 6px;
  border: 1px solid #f6f6f6;
  cursor: pointer;
  position: relative;
}
.filter img {
  margin-right: 5px;
}
.filter span {
  font-weight: 600;
  font-size: 12px;
  color: #251f1c;
}

.menu {
  position: absolute;
  bottom: -8rem;
  background: #ffffff;
  border: 1px solid #f6f6f6;
  right: 0px;
  display: flex;
  flex-direction: column;
  width: 122px;
  height: 126px;
  border-radius: 6px;
  z-index: 999;
}
.menu span {
  margin: 0 auto;
  height: 32px;
  margin-top: 5px;
  border-radius: 6px;
  font-weight: 400;
  font-size: 12px;
  color: #251f1c;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
}
.menu span:hover {
  background-color: #fafafb;
}
/* --------------------------------------- */

.CircleContainer {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 30%;
  /* min-height: 540px; */
}

/* --------------------------------------- */

.BarContainer {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 49%;
  min-height: 327px;
  /* margin: 0 auto; */
}

/* --------------------------------------- */

.WheelContainer {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 49%;
  min-height: 327px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.full_circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
.wheelHeader {
  min-width: 150px;
  max-width: 150px;
}

/* --------------------------------------- */

.parent {
  position: relative;
  width: 350px;
  height: 350px;
  border-radius: 50%;
}
.childone {
  position: absolute;
  top: 50px;
  left: 48px;
  width: 250px;
  height: 250px;
  border-radius: 50%;
}
.childtwo {
  position: absolute;
  top: 99px;
  left: 97px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

/* --------------------------------------- */

.parentPrg {
  position: relative;
}
.childonePrg {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 125px;
  left: 123px;
  background-color: #f55053;
  border-radius: 50%;
}

/* --------------------------------------- */

.insiteContain {
  display: flex;
  margin-top: 3rem;
  justify-content: space-between;
}
.barContain {
  margin-top: 3rem;
}

/* --------------------------------------- */

@media (max-width: 1200px) {
  .MultiLineContent {
    flex-direction: column;
  }
  .WrapeRight {
    margin-top: 1rem;
  }
  .filter {
    margin-top: 1rem;
  }
  .insiteContain {
    flex-direction: column;
  }
  .CircleContainer {
    width: 100%;
  }
  .WheelContainer {
    width: 100%;
    margin-top: 3rem;
  }
  .BarContainer {
    width: 100%;
  }
}
.WheelPie {
  display: flex;
  align-items: center;
  justify-content: center;
}
