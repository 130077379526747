.profile {
  &__btns {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    &__save {
      color: $backgroundNavbar;
      background-color: $greenColor;
      margin-right: 0.5rem;
      &:hover {
        border: 1px solid transparent;
        color: $backgroundNavbar;
        background-color: $greenColor;
      }
      &:focus {
        border: 1px solid transparent;
        box-shadow: none;
        color: $backgroundNavbar;
        background-color: $greenColor;
      }
    }
    &__cancel {
      color: $backgroundNavbar;
      background-color: $orangeColor;
      &:hover {
        border: 1px solid transparent;
        color: $backgroundNavbar;
        background-color: $orangeColor;
      }
      &:focus {
        border: 1px solid transparent;
        color: $backgroundNavbar;
        background-color: $orangeColor;
        box-shadow: none;
      }
    }
  }
  &__form {
    padding: 3rem;
    .ant-form-item-label {
      margin-bottom: 0.4rem;
    }
    .inputs.ant-input {
      width: 70%;
      height: 2.7rem;
    }
    .inputPassword {
      width: 70%;
      height: 2.7rem;
    }
    &__change {
      height: 2.5rem;
      margin-top: 0.6rem;
    }
    &__image {
      .ant-avatar-image {
        width: 10%;
        border: 1px solid transparent;
        border-radius: 50%;
        height: 28%;
        margin-bottom: 1.2rem;
      }
      &__drag {
        display: flex;
        flex-wrap: wrap;
        &__remove {
          margin-bottom: 1.5rem;
          border: 1px solid $orangeColor;
          height: 2.4rem;
          color: $orangeColor;
          &:hover {
            background-color: $orangeColor;
            color: $backgroundNavbar;
            border: 1px solid transparent;
          }
          &:focus {
            box-shadow: none;
            border: 1px solid $orangeColor;
            color: $orangeColor;
            background-color: $backgroundNavbar;
          }
        }
      }
    }
  }
}
