.sidebar {
  min-height: 800px;
  background: #f5f5f5;
}
.sidebar .ant-menu {
  background: #f5f5f5;
}
// .color-Item .ant-menu-item-selected {
//   color: $mainColor !important;
// }
// .color-Item .ant-menu-vertical .ant-menu-item::after,
// .ant-menu-vertical-left .ant-menu-item::after,
// .ant-menu-vertical-right .ant-menu-item::after,
// .ant-menu-inline .ant-menu-item::after {
//   border-right: 3px solid $mainColor;
// }
// .color-Item .ant-menu-light .ant-menu-item:hover,
// .ant-menu-light .ant-menu-item-active,
// .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
// .ant-menu-light .ant-menu-submenu-active,
// .ant-menu-light .ant-menu-submenu-title:hover {
//   color: $mainColor !important;
// }

.color-Item .ant-menu-item-selected
/* ,.ant-menu-item:hover */ {
  // border-radius: 25px;
  border-radius: 10px;
  // background-color: #0032cb !important;
  // color: white !important;
  height: 3rem;
  cursor: pointer;
  transition: none !important;
  box-shadow: none !important;
}
// .color-Item .ant-menu-item:hover {
//   color: gainsboro !important;
// }
.ant-menu-item,
.ico {
  padding: 18px !important;
}

.color-Item,
.ant-menu-inline {
  border-right: none !important;
}
.ant-layout-sider-children {
  margin: 0 0.5rem;
}
.color-Item .ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: none;
  transition: none;
  box-shadow: none;
}
.color-Item .ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #0e4bfb !important;
  transition: none !important;
  box-shadow: none !important;
}

.ant-layout-sider-zero-width-trigger {
  width: 1.5rem;
  margin-right: 1rem;
  top: -1px;
  color: #0e4bfb !important;
  @media (max-width: 1000px) {
    .ant-layout-sider-light {
      display: none !important;
    }
    .ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
      display: none !important;
    }
    .ant-layout-sider-zero-width-trigger-left {
      display: none !important;
    }
    .anticon {
      display: none !important;
    }
  }
}

.ant-menu-item:focus,
.ant-menu-item-active,
.ant-menu-submenu-active {
  background: none;
}
.ant-menu-item:active {
  background: none !important;
}
.ant-menu-item:active {
  transition: none !important;
  box-shadow: none !important;
}
.ant-menu-item,
.ant-menu-submenu-title {
  transition: none !important;
}
@media (max-width: 700px) {
  .sidebar {
    display: none;
  }
}
// topic Menue
.inline_text {
  pointer-events: none;
  border-top: 1px solid #eef0f1;
  margin-top: 1.5rem !important;
}
.inline_text span {
  color: #42526e;
  font-size: 13px;
  font-weight: 700 !important;
}
.ant-layout-sider .ant-layout-sider-light .sidebar {
  flex: 0 0 200px;
  max-width: 300px;
  min-width: 210px;
  width: 200px;
}
// .test .ant-layout-sider {
//   flex: 0 0 0px;
//   max-width: 0px;
//   min-width: 0px;
//   width: 0px;
// }
.ant-layout-sider
  .ant-layout-sider-light
  .ant-layout-sider-collapsed
  .ant-layout-sider-zero-width
  .test {
  flex: 0 0 0px;
  max-width: 0px;
  min-width: 0px;
  width: 0px;
}
.ico_bg {
  // background-color: white !important;
  background: #f5f5f5;

  color: rgba(0, 0, 0, 0.85) !important;
}
.ico_color {
  background-color: #f5f8ff !important;
  color: #0e4bfb !important;
  border-radius: 25px;
  height: 2.6rem !important;
}
.wrapIco {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapIco img {
  flex: 0.2;
  max-width: 24px;
  max-height: 24px;
}

.wrapIco span {
  flex: 0.8;
}
.color-Item .ant-menu-item:hover {
  color: #1e1e1e !important;
}

.color-Item .ant-menu-item-selected:hover {
  color: #0e4bfb !important;
}
.ant-menu-title-content {
  display: flex;
  align-items: center;
}
.ico img {
  width: 21px;
  height: 21px;
  margin-right: 6px;
}

.ico span {
  font-weight: bold;
  font-size: 14px;
}
.ico img.arrow-img {
  width: 14px;
  height: 14px;
  margin: 0 6px;
}
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: unset;
}
.ant-menu {
  transition: none !important;
}
.ant-menu-item,
.ant-menu-submenu-title {
  transition: none !important;
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 35px;
  margin-top: 2px;
}
