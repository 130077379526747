.ant-btn-primary {
  background: $mainColor;
}
.ant-btn:hover {
  background: transparent;
  color: $mainColor;
  border: 1px solid $mainColor;
}
.ant-btn {
  border: 1px solid transparent;
  border-radius: 25px;
  font-weight: bold;
}
.btn {
  background-color: #002984;
}

.topHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  flex-wrap: wrap;
}

.table2 {
  overflow: auto;
}

.ant-table.ant-table-rtl {
  overflow-x: scroll;
}
.ant-btn-primary:focus {
  background: $mainColor;
  color: $backgroundNavbar;
}
.add-btn {
  width: 100%;
  height: 2.5rem;
  border-radius: 38px;
  margin-top: 1rem;
}
.container {
  border: 1px solid #f1f1f5;
  box-sizing: border-box;
  border-radius: 18px;
  background: #ffffff;
  padding: 1.2rem;
}

.btn-search .ant-input-search-button {
  border: 2px solid #d9d9d9;
  border-top-right-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
}

.ant-input-search-button {
  height: 33px;
}
.ant-input {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.ant-pagination-item {
  border-radius: 8px;
  color: $grayLight;
}
.ant-pagination-item-active {
  background-color: $mainColor;
}
.ant-pagination-item-active a {
  color: $backgroundNavbar;
}
.ant-pagination-prev {
  border-radius: 8px;
}
.ant-pagination-prev .ant-pagination-item-link {
  border-radius: 8px;
}
.ant-pagination-next .ant-pagination-item-link {
  border-radius: 8px;
}

.ant-pagination {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.ant-table-pagination.ant-pagination {
  margin-top: 1.5rem;
}
.ant-pagination-item:hover {
  border-color: #0032cb !important;
}
.modal .ant-modal-content {
  border: 1px solid transparent;
  border-radius: 25px;
}
.modal .ant-modal-header {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.ant-form-item-control-input-content .ant-input {
  border-radius: 25px;
}
.ant-form-item-control-input-content .ant-input-password {
  border-radius: 25px;
}
.select-p .ant-select-selector {
  border-radius: 25px !important;
}
.selectCompanyService .ant-select-selection-placeholder,
.selectCompanyService .ant-select-selection-item {
  line-height: normal !important;
}

.delete:hover {
  color: $backgroundNavbar;
  background-color: $orangeColor;
  border: 1px solid transparent;
}
.edit:hover {
  color: $backgroundNavbar;
  background-color: #002984;
  border: 1px solid transparent;
}
.edit:focus {
  color: $backgroundNavbar;
  background-color: #002984;
  border: 1px solid transparent;
}
.container .table2 .ant-checkbox-checked .ant-checkbox-inner {
  background-color: $mainColor;
}
.container
  .table2
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}
.container
  .table2
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child {
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}
.container .table2 .ant-table-thead > tr > th {
  font-weight: bold;
}
/* input box-shadow */
.ant-input-affix-wrapper > input.ant-input:focus {
  -webkit-box-shadow: 0 0 0 30px white inset;
}
.ant-input:focus,
.ant-input-focused {
  border-color: #0032cb;
  box-shadow: unset;

  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #0032cb;
  box-shadow: unset;
  border-right-width: 1px;
  outline: 0;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #0032cb;
}
.reInput {
  width: 100%;
  height: 3.2rem;
  border-radius: 12px;
}
.ant-input:hover {
  border-color: #0032cb;
  border-right-width: 1px;
}
