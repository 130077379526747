.container {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    @media screen and (max-width: 768px) {
      justify-content: space-between;
    }
    @media screen and (max-width: 500px) {
      justify-content: flex-start;
    }
    &__span1 {
      color: $greenColor;
      font-weight: bold;
      margin-right: 0.5rem;
    }

    &__column1 {
      &__content {
        .ant-avatar-image {
          width: 5rem;
          height: 5rem;
          margin-right: 2rem;
        }
      }
    }
    &__column2 {
      max-width: 33%;
      overflow: hidden;
      @media screen and (max-width: 800px) {
        max-width: 100%;
      }
    }
  }
}
.above {
  margin: 0.8rem 0;
}
