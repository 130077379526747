.disputes_info_col {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  border-radius: 10px;
  padding: 1rem;
  background-color: white;
}
.disputes_info_row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.disputes_section {
  display: flex;
  justify-content: space-between;
}
.disputes .ant-modal-header {
  background: #f5f5f5;
}
.disputes .ant-modal-content {
  background: #f5f5f5;
}
.disputes_section h3 {
  color: rgb(175, 175, 175);
  font-weight: normal;
  font-size: 14px;
}
.disputes_section span {
  font-weight: bold;
}
