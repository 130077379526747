.card {
  border-radius: 8px;
  width: 19%;
  height: 18%;
  margin-left: 2rem;
  &__header {
    display: flex;
    justify-content: space-between;
    margin-top: 0.9rem;
    &__title {
      color: $grayDark;
      font-weight: bold;
    }
  }
  &__body {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
  }
}
.container-cards {
  display: flex;
  justify-content: center;
  margin: 0.4rem;
  flex-wrap: wrap;
  align-items: center;
}
.dark,
.green,
.yellow,
.blue {
  margin: 1rem;
}
.dark .number {
  color: #764eca;
  font-weight: bold;
}
.yellow .number {
  color: #fee877;
  font-weight: bold;
}
.green .number {
  color: #33b978;
  font-weight: bold;
}
.blue .number {
  color: #0062ff;
  font-weight: bold;
}
.transaction-chart {
  background-color: $backgroundNavbar;
  border: 1px solid transparent;
  border-radius: 18px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  min-height: 456px;
}
.accept-declined {
  width: 90%;
  border: 1px solid transparent;
  border-radius: 18px;
  background-color: $backgroundNavbar;
  margin: 0 auto;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  h2 {
    color: $grayDark;
    font-weight: bold;
  }
  &__accept {
    margin-top: 0.5rem;
    margin: 1rem;
  }
}

@media (max-width: 414px) {
  .accept-declined {
    width: 100%;
  }
}
.secondPart {
  display: flex;
  justify-content: space-between;
  margin: 0 auto !important;
}
.secondPartBar {
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
  margin-top: -2rem;
}
// .BaseInsightsStyles_BarContainer___Tb07 {
//   min-width: 660px !important;
// }
