.ant-input-number-input {
  margin: 5px auto !important;
}

.valueSelect .ant-select-selection-placeholder {
  margin: 10px auto !important;
}
.ant-select-selection-placeholder {
  margin: 5px auto !important;
}
.ant-select-selection-item {
  margin: 0px auto !important;
}
.valueSelect .ant-select-selection-item {
  margin: 5px auto !important;
}
.ant-select-multiple .ant-select-selection-placeholder {
  top: 39%;
}
.heightSelect .ant-select-selector {
  height: 2.7rem !important;
}
.eye .anticon svg {
  color: #696974;
}
.branch {
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
