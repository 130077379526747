// #loading {
//   position: fixed;
//   min-height: 100vh;
//   z-index: 9999;
//   background-color: rgba(251, 251, 252, 0.7);
//   width: 100%;
//   text-align: center;
//   display: -webkit-flex;
//   display: flex;
//   -webkit-align-items: center;
//   align-items: center;
//   -webkit-justify-content: center;
//   justify-content: center;
//   top: 0;
//   left: 0;
// }
// .bokeh {
//   border: 0.01em solid rgba(150, 150, 150, 0.1);
//   border-radius: 50%;
//   font-size: 100px;
//   height: 1em;
//   list-style: outside none none;
//   margin: 0 auto;
//   position: relative;
//   top: 35%;
//   width: 1em;
//   z-index: 2147483647;
// }
// .bokeh li {
//   border-radius: 50%;
//   height: 0.2em;
//   position: absolute;
//   width: 0.2em;
// }
// .bokeh li:nth-child(1) {
//   animation: 1.13s linear 0s normal none infinite running rota,
//     3.67s ease-in-out 0s alternate none infinite running opa;
//   background: #ff594e none repeat scroll 0 0;
//   left: 50%;
//   margin: 0 0 0 -0.1em;
//   top: 0;
//   transform-origin: 50% 250% 0;
// }
// .bokeh li:nth-child(2) {
//   animation: 1.86s linear 0s normal none infinite running rota,
//     4.29s ease-in-out 0s alternate none infinite running opa;
//   background: #136bb1 none repeat scroll 0 0;
//   margin: -0.1em 0 0;
//   right: 0;
//   top: 50%;
//   transform-origin: -150% 50% 0;
// }
// .bokeh li:nth-child(3) {
//   animation: 1.45s linear 0s normal none infinite running rota,
//     5.12s ease-in-out 0s alternate none infinite running opa;
//   background: #4fa22b none repeat scroll 0 0;
//   bottom: 0;
//   left: 50%;
//   margin: 0 0 0 -0.1em;
//   transform-origin: 50% -150% 0;
// }
// .bokeh li:nth-child(4) {
//   animation: 1.72s linear 0s normal none infinite running rota,
//     5.25s ease-in-out 0s alternate none infinite running opa;
//   background: #4fa22b none repeat scroll 0 0;
//   margin: -0.1em 0 0;
//   top: 50%;
//   transform-origin: 250% 50% 0;
// }
// @keyframes opa {
//   12% {
//     opacity: 0.8;
//   }
//   19.5% {
//     opacity: 0.88;
//   }
//   37.2% {
//     opacity: 0.64;
//   }
//   40.5% {
//     opacity: 0.52;
//   }
//   52.7% {
//     opacity: 0.69;
//   }
//   60.2% {
//     opacity: 0.6;
//   }
//   66.6% {
//     opacity: 0.52;
//   }
//   70% {
//     opacity: 0.63;
//   }
//   79.9% {
//     opacity: 0.6;
//   }
//   84.2% {
//     opacity: 0.75;
//   }
//   91% {
//     opacity: 0.87;
//   }
// }

// @keyframes rota {
//   100% {
//     transform: rotate(360deg);
//   }
// }
.loading-container {
  background-color: #0043ff;
  position: relative;
  height: 100vh;
  width: 100vw;
  /* overflow: hidden; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.loading-content {
  text-align: center;
}

.loading-message {
  font-size: 3rem;
  color: #fff;
}

.loading-dot {
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  /* opacity: 0; */
  display: inline-block;
  margin-right: 8px;
}

.loading-dot:last-child {
  margin-right: 0;
}

.loading-dot:nth-child(1) {
  margin-left: 0.5rem;

  animation: loading-dot-animation 2s 0.33s infinite linear;
}

.loading-dot:nth-child(2) {
  animation: loading-dot-animation 2s 0.67s infinite linear;
}

.loading-dot:nth-child(3) {
  animation: loading-dot-animation 2s 1s infinite linear;
}

@keyframes loading-dot-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.stars {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.star {
  position: absolute;
  background-image: url("../../../assets/images/star.svg");
  background-size: contain;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  /* opacity: 0.5; */
  animation: star-animation 3s infinite linear;
}

@keyframes star-animation {
  0% {
    transform: translateX(-50%) translateY(-50%) scale(1);
  }
  100% {
    transform: translateX(150%) translateY(150%) scale(1.5);
  }
}

.star.star-small {
  animation-duration: 2s;
}

.star.star-medium {
  animation-duration: 3s;
}

.star.star-large {
  animation-duration: 4s;
}

.paper-image {
  position: absolute;
  width: 100px;
  height: 100px;
  z-index: 2;
}

.paper-image1 {
  top: 0px;
  left: 0px;
  background-image: url("../../../assets/images/paperFolderLoading1.svg");
  background-size: cover;
}

.paper-image2 {
  top: 300px;
  left: 0px;
  background-image: url("../../../assets/images/paperFolderLoading1.svg");
  background-size: cover;
}

.paper-image3 {
  top: 600px;
  left: 0px;
  background-image: url("../../../assets/images/paperFolderLoading1.svg");
  background-size: cover;
}
.paper-image4 {
  top: 0px;
  right: 0px;
  background-image: url("../../../assets/images/paperFolderLoading2.svg");
  background-size: 100% 100%;
  width: 15rem;
}

.paper-image5 {
  top: 300px;
  right: 0px;
  background-image: url("../../../assets/images/paperFolderLoading2.svg");
  background-size: 100% 100%;
  width: 15rem;
}

.paper-image6 {
  top: 600px;
  right: 0px;
  background-image: url("../../../assets/images/paperFolderLoading2.svg");
  background-size: 100% 100%;
  width: 15rem;
}
.paper-image7 {
  top: 100px;
  left: 600px;
  background-image: url("../../../assets/images/paperFolderLoading.svg");
  background-size: 100% 100%;
  width: 15rem;
}

.paper-image8 {
  top: 500px;
  left: 600px;
  background-image: url("../../../assets/images/paperFolderLoading.svg");
  background-size: 100% 100%;
  width: 15rem;
}
@media (max-width: 1280px) {
  .paper-image7 {
    top: 100px;
    left: 450px;
  }
  .paper-image8 {
    top: 600px;
    left: 450px;
  }
}
@media (max-width: 1024px) {
  .paper-image7 {
    top: 100px;
    left: 350px;
  }
  .paper-image8 {
    top: 400px;
    left: 350px;
  }
  .paper-image3 {
    top: 500px;
    left: 0px;
  }
  .paper-image6 {
    top: 500px;
    right: 0px;
  }
}
@media (max-width: 1000px) {
  .paper-image2 {
    top: 50%;
    left: 0px;
  }
  .paper-image3 {
    top: 90%;
    left: 0px;
  }
  .paper-image5 {
    top: 50%;
    right: 0px;
  }
  .paper-image6 {
    top: 90%;
    right: 0px;
  }
  .paper-image7 {
    top: 20%;
    left: 30%;
  }
  .paper-image8 {
    top: 80%;
    left: 30%;
  }
}
/* @media (max-width: 768px) {
  .loading-content {
    max-width: 290px;
    text-align: start;
    margin-left: 6rem;
  }
  .loading-message {
    font-size: 2.5rem;
  }
  .loading-dot {
    width: 5px;
    height: 5px;
  }
  .paper-image {
    width: 70px;
    height: 70px;
  }
  .paper-image3 {
    top: 550px;
    left: 0px;
  }
  .paper-image6 {
    top: 550px;
    right: 0px;
  }
  .paper-image4,
  .paper-image5,
  .paper-image6 {
    width: 10rem;
  }
  .paper-image7 {
    display: none;
  }
  .paper-image8 {
    display: none;
  }
} */
@media (max-width: 768px) {
  .loading-content {
    max-width: 290px;
    text-align: start;
    margin-left: 6rem;
  }
  .loading-message {
    font-size: 2.5rem;
  }
  .loading-dot {
    width: 5px;
    height: 5px;
  }
  .paper-image {
    width: 70px;
    height: 70px;
  }
  .paper-image4,
  .paper-image5,
  .paper-image6 {
    width: 10rem;
  }
  .paper-image7,
  .paper-image8 {
    width: 10rem;
  }
  .paper-image2 {
    top: 40%;
    left: 0px;
  }
  .paper-image3 {
    top: 80%;
    left: 0px;
  }
  .paper-image5 {
    top: 40%;
    right: 0px;
  }
  .paper-image6 {
    top: 80%;
    right: 0px;
  }
  .paper-image7 {
    top: 10%;
    left: 20%;
  }
  .paper-image8 {
    top: 70%;
    left: 20%;
  }
}
