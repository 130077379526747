span a {
  text-decoration: none !important;
  color: #808191 !important;
}
.sub-title {
  margin-bottom: 10px;
}
.dashboard {
  background-color: #fafafb;

  min-height: 1000px;
  margin-left: 17rem;
}

.header-table {
  display: flex;
  justify-content: space-between;

  font-family: $Sans;

  &__h4 {
    font-weight: bold;
    color: #1e1e1e;
  }
}
.table1 .ant-checkbox-checked .ant-checkbox-inner {
  background-color: $mainColor;
}
.table1 .ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}
.table1 .ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}
.table1 .ant-table-thead > tr > th {
  background: $mainColor !important;
  color: $backgroundNavbar !important;
  font-weight: bold;
}
.rowCardsDashboard {
  margin: 0 auto;
  // max-width: 1250px;
}
.rowTableDashboard {
  margin-top: 1rem;
}
@media (max-width: 1000px) {
  .latestEvent {
    display: none;
  }
  .ant-card-bordered {
    width: 90%;
    margin: 0 auto;
  }
}
@media (max-width: 700px) {
  .paddingCard {
    padding-left: unset !important;
    padding-right: unset !important;
  }
  .ant-card-bordered {
    width: 95%;
    // width: unset;
    margin: 0 auto;
    height: 9rem;
    // margin-left: 10px;
  }
  .ant-card-head-title {
    overflow: unset;
    font-size: 1.05rem;
    text-overflow: unset;
  }
}
