.logo_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 170px;
}
.profile_section {
  display: flex;
  align-items: center;
}
.__border {
  width: 1.5px;
  height: 2rem;
  background-color: #8a8f92;
  margin-right: 1rem;
}
.menu_logo {
  color: blue;
  margin-left: 6rem;
}
.hover-image {
  display: none;
}
.item_hover {
  color: #1e1e1e !important;
}
.item_hover:hover .hover-image {
  display: block;
}

.item_hover:hover .default-image {
  display: none;
}
.item_hover:hover {
  background-color: #f5f8ff;
  border-radius: 10px;
}
.colorLink2 {
  color: #ff4cad !important;
}
.colorLink {
  color: #1e1e1e !important;
}
.colorLink:hover,
.colorLink2:hover {
  color: #0e4bfb !important;
}
@media (max-width: 700px) {
  .logo_container {
    display: none;
  }
}
.menuContainer {
  padding: 1rem;
  width: 15rem;
  border-radius: 15px;
  height: 220px;
}
.menuHeight {
  padding: 1rem;
  width: 14rem;
  border-radius: 15px;
  height: 180px;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: 10px;
  font-size: 16px;
  transition: unset !important;
}
.first_item_hover:hover {
  background-color: transparent;
}
