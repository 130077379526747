.edit-transaction {
  color: $backgroundNavbar;
  // background-color: $greenColor;
  background-color: #002984;
}
.edit-transaction:hover {
  color: $backgroundNavbar;
  background-color: #002984;
  border: 1px solid transparent;
}
.edit-transaction:focus {
  color: $backgroundNavbar;
  background-color: #002984;
  border: 1px solid transparent;
}
.declined {
  color: $backgroundNavbar;
  height: 2rem;
  &:focus {
    color: $backgroundNavbar;
    background-color: $orangeColor;
    border: 1px solid transparent;
  }
  &:hover {
    color: $backgroundNavbar;
    background-color: $orangeColor;
    border: 1px solid transparent;
  }
}

.paid {
  color: $backgroundNavbar;
  background-color: $greenColor;
  &:focus {
    color: $backgroundNavbar;
    background-color: $greenColor !important;
    border: 1px solid transparent;
  }
  &:hover {
    color: $backgroundNavbar;
    background-color: $greenColor;
    border: 1px solid transparent;
  }
}
.btns .ant-form-item-control-input-content {
  margin-top: 0.3rem;
  padding-bottom: 0px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.btns {
  &__offer-save {
    margin-top: 1.5rem;
    width: 100%;
    margin-right: -2.5rem;
    background-color: $greenDark;
    color: $backgroundNavbar;
    width: 9rem;
    height: 2.5rem;
    &:focus {
      background-color: $greenDark;
      color: $backgroundNavbar;
      border: 1px solid transparent;
    }
    &:hover {
      background-color: $greenDark;
      color: $backgroundNavbar;
      border: 1px solid transparent;
    }
  }
  &__offer-cancel {
    margin-top: 1.5rem;

    width: 100%;
    background-color: $orangeColor;
    color: $backgroundNavbar;
    width: 9rem;
    height: 2.5rem;
    &:focus {
      background-color: $orangeColor;
      color: $backgroundNavbar;
      border: 1px solid transparent;
    }
    &:hover {
      background-color: $orangeColor;
      color: $backgroundNavbar;
      border: 1px solid transparent;
    }
  }
}
.offer-name .ant-form-item-label {
  margin-bottom: 0.5rem;
}
.offerImage .ant-upload-list-item {
  width: 100%;
  border-radius: 10px !important;
  height: 2rem !important;
}
.note {
  color: $orangeColor;
  margin-top: 1rem;
}
.industry {
  margin-top: -1.1rem;
}
.end .ant-space-item {
  display: flex;
  justify-content: flex-end;
}
.input-border {
  border-radius: 25px;
  height: 2.4rem;
}

//swal
.swal2-modal {
  border-radius: 26px !important;
}
.swal2-confirm {
  border-radius: 15px !important;

  width: 6rem;
  background-color: $mainColor !important;
}
.swal2-cancel {
  border-radius: 15px !important;
  width: 6rem;
  background-color: $backgroundNavbar !important;
  color: $mainColor !important;
  font-weight: bold !important;
}
.swal2-html-container {
  margin-top: 0.5rem !important;
}
.labelImage .ant-form-item-label {
  margin-top: 1.3rem !important ;
}
// .ant-select-single .ant-select-selector .ant-select-selection-item{
//   line-height:normal !important;
// }
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: normal !important;
}
@media (max-width: 950px) {
  .ant-layout-sider-zero-width-trigger {
    display: none !important;
  }
}
.menu_logo.anticon svg {
  width: 2rem;
  height: 1.5rem;
}

@media (max-width: 920px) {
  .menu_logo.anticon svg {
    display: none;
  }
}

.ant-input-affix-wrapper > input.ant-input:focus {
  -webkit-box-shadow: 0 0 0 30px white inset;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill:visited {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

// layout content
.content {
  background-color: #fafafb;
}
// date picker
.ant-picker-range {
  border-radius: 10px !important;
}
.ant-picker {
  border: 1px solid #d9d9d9;
}

.ant-picker:hover,
.ant-picker-focused {
  border: 1px solid;
  border-color: $mainColor;
  box-shadow: none;
}
.ant-picker-input > input:focus,
.ant-picker-input > input-focused {
  border-color: $mainColor;
}
.ant-picker-input .ant-picker-input-active {
  border-color: $mainColor;
}

.ant-picker-range .ant-picker-active-bar {
  background-color: $mainColor !important;
  color: white;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: $mainColor;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $mainColor;
}
.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: $mainColor;
}
.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border: 2px solid#d9d9d9;
}
.ant-input-search .ant-input:focus {
  border: 2px solid $mainColor;
}
.ant-input:hover {
  border-color: $mainColor;
}
.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: $mainColor;
}
.select-p:hover,
.select-p:focus {
  border-color: $mainColor !important;
  box-shadow: none !important;
}
.ant-select-selector:hover,
.ant-select-selector:focus {
  border-color: $mainColor !important;
  box-shadow: none !important;
  transition: none !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: $mainColor !important;
  box-shadow: none !important;
}

.ant-select
  .ant-select-has-feedback
  .select-p
  .ant-select-single
  .ant-select-allow-clear
  .ant-select-show-arrow
  .ant-select-show-search {
  box-shadow: none !important;
}
.ant-input-number {
  border: 2px solid#d9d9d9;
}
.ant-input-number:hover {
  border: 2px solid $mainColor;

  border-color: $mainColor;
}
.ant-input-number:focus,
.ant-input-number-focused {
  box-shadow: none;
}

.ant-btn-primary:focus .ant-btn-primary:active {
  text-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn:focus::after,
.ant-btn:active::after {
  text-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-primary:active {
  color: #fff;
  border-color: $mainColor;
  background: $mainColor;
}
// date border
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: $mainColor;
  border-radius: 5px;
}
.ant-picker-input > input:placeholder-shown {
  margin-right: -2rem;
}
@media (max-width: 700px) {
  .ant-picker-input > input:placeholder-shown {
    margin-right: unset;
  }
}
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: #fff;
  background: #0032cb;
  border-color: #0032cb;
  padding: 0 0.4rem;
  cursor: pointer;
  border-radius: 10px;
}
.messageNoData {
  border: 1px solid transparent;
  border-radius: 15px;
  background: #f5f8ff;
  width: 22%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
  color: $mainColor;
  font-weight: bold;
  padding: 0.8rem;
}
.messageSuccess .ant-message-notice-content {
  background-color: #ebffeb;
  // border: 1px solid rgb(51, 185, 120);
  border-radius: 10px;
  color: rgb(51, 185, 120);
}
.messageError .ant-message-notice-content {
  background-color: rgb(254 246 246);
  // border: 1px solid rgb(51, 185, 120);
  border-radius: 10px !important;
  color: rgb(245, 80, 83) !important;
}
@media (max-width: 700px) {
  .filterButtonTrans {
    gap: 2px !important;
  }
  .messageNoData {
    width: 100%;
  }
  .rowMoneyLearn {
    flex-direction: column;
  }
  .formIndividual .ant-form-item-control-input-content {
    max-width: 70% !important;
  }
}
.ant-form-item-with-help .ant-form-item-explain {
  min-height: 35px;
}
.btnWAll {
  background-color: #f1f1f5;
  color: $mainColor;
}
.btnWAll:hover {
  background-color: #f1f1f5;
  color: $mainColor;
  border: 1px solid #f1f1f5 !important;
}
.btnWAll:focus {
  background-color: $mainColor;
  color: white;
  border: 1px solid $mainColor !important;
}
.btnWPaid {
  background-color: #f1f1f5;
  color: #20ce24;
}
.btnWPaid:hover {
  background-color: #f1f1f5;
  color: #20ce24;
  border: 1px solid #f1f1f5 !important;
}
.btnWPaid:focus {
  background-color: #20ce24;
  color: white;
  border: 1px solid #20ce24 !important;
}
.btnWPending {
  background-color: #f1f1f5;
  color: #fecd2f;
}
.btnWPending:hover {
  background-color: #f1f1f5;
  color: #fecd2f;
  border: 1px solid #f1f1f5 !important;
}
.btnWPending:focus {
  background-color: #fecd2f;
  color: white;
  border: 1px solid #fecd2f !important;
}
.btnWDecliend {
  background-color: #f1f1f5;
  color: $orangeColor;
}
.btnWDecliend:hover {
  background-color: #f1f1f5;
  color: $orangeColor;
  border: 1px solid #f1f1f5 !important;
}
.btnWDecliend:focus {
  background-color: $orangeColor;
  color: white;
  border: 1px solid $orangeColor !important;
}
.anticon-bold {
  margin-right: -0.2rem;
}
.ant-spin-dot-item {
  background-color: #0032cb;
  opacity: unset;
}
.ant-spin-dot .ant-spin-dot-item:nth-child(1) {
  background-color: #fd5394;
}
.ant-spin-dot .ant-spin-dot-item:nth-child(2) {
  background-color: #20ce24;
}
.ant-spin-dot .ant-spin-dot-item:nth-child(3) {
  background-color: #0e4bfb;
}
.ant-spin-dot .ant-spin-dot-item:nth-child(4) {
  background-color: #fecd2f;
}
.imageExport {
  width: 19px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.exportBlue {
  display: none;
}
.btnExport:hover .imageExport {
  display: none;
}
.btnExport:hover .exportBlue {
  display: block;
  width: 19px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}
// radio button
.ant-radio-checked .ant-radio-inner {
  border-color: $mainColor;
}
.ant-radio-inner::after {
  background-color: $mainColor;
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: none;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: $mainColor;
}
// Action swal for focus
.swal2-styled.swal2-cancel:focus {
  box-shadow: none !important;
}

.orix-Warning {
  margin-bottom: 0.24rem;
  margin-right: 0.25rem;
}
.businessOfferBtns {
  display: flex;
  align-items: center;
}
.custom-cascader-dropdown-menu {
  height: 165px !important;
}
.ant-btn-danger {
  background-color: #fd5394;
}
.ant-input-textarea-status-success .ant-input-feedback-icon,
.ant-input-affix-wrapper-status-success .ant-input-feedback-icon {
  color: #20ce24;
}
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  color: #fd5394;
}
.ant-select-status-error .ant-select-feedback-icon {
  color: #fd5394;
}
.ant-input-affix-wrapper-status-error .ant-input-feedback-icon {
  color: #fd5394;
}
.ant-form-item-explain-error {
  color: #fd5394;
}
.ant-input-affix-wrapper-status-error:not(
    .ant-input-affix-wrapper-disabled
  ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(
    .ant-input-affix-wrapper-disabled
  ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  border-color: #fd5394;
}
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #fd5394 !important;
}
/* messages */
.messageSuccess .ant-message-notice-content {
  background-color: #ebffeb;
  /* // border: 1px solid rgb(51, 185, 120); */
  border-radius: 10px;
  color: #20ce24;
}
.messageError .ant-message-notice-content {
  background-color: rgb(254, 246, 252);
  /* // border: 1px solid rgb(51, 185, 120); */
  border-radius: 10px !important;
  color: #fd5394 !important;
}
.messageWarning .ant-message-notice-content {
  background-color: rgb(249, 243, 233);
  /* // border: 1px solid rgb(51, 185, 120); */
  border-radius: 10px !important;
  color: #fecd2f !important;
}
// .messageError .anticon {
//   color: #fd5394 !important;
// }
.messageSuccess .anticon {
  color: #20ce24 !important;
}
.selectLevel.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 2.7rem !important;
}
.selectLevel.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow
  .selectLevel.ant-select-selection-placeholder {
  line-height: 40px !important;
}
.selectLevel .ant-select-selection-placeholder {
  margin: 12px auto !important;
}
.rowAlign {
  margin-bottom: 0.5rem;
}
.rowAlign .spanColor {
  color: rgb(111, 111, 111);
}
.userCardDetails {
  background: #f5f5f5;
  border-radius: 25px;
  padding: 1rem 0rem 0rem;
  // width: "80%",
  justify-content: space-around;
  margin: 0 auto;
}
@media (max-width: 700px) {
  .userCardDetails {
    justify-content: flex-start;
    padding: 1rem;
  }
}
.ant-collapse-content {
  border-top: unset;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: unset;
}
.ant-switch-inner {
  color: unset;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: unset !important;
}
.ql-container.ql-snow {
  border-radius: 25px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.ql-toolbar.ql-snow {
  border-radius: 25px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.quill {
  height: 150px;
}
.btnFair {
  margin: 0 auto;
  margin-top: 5rem;
}
@media (max-width: 700px) {
  .quill {
    height: unset;
  }
  .btnFair {
    margin-top: 1rem;
  }
}
.blueSwitch.ant-switch-checked {
  background-color: #0e4bfb;
}
.selectMerchant .ant-select-selector {
  height: 2.6rem !important;
}
.selectMerchant .ant-select-selection-placeholder {
  margin: 10px auto !important;
}
.selectMerchant .ant-select-selection-item {
  margin: 5px auto !important;
}
.greenSwitch.ant-switch-checked {
  background-color: #20ce24;
}
.ant-badge {
  margin-right: 0.8rem;
}
.kiweImage {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.kiweImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.articleContent .ant-card-body {
  font-weight: 500;
}
.selectRole .ant-select-selector {
  height: 2.5rem !important;
  border-radius: 25px;
}
.selectRole .ant-select-selection-item {
  margin: 5px auto !important;
}
.selectRole.ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  margin: 10px auto !important;
}

