.creditContainer {
  width: 90%;
  margin: 0 auto;
}

.creditCardImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.creditBtns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.creditBtn {
  width: 10rem;
  height: 2.5rem;
}
.creditBtnAccept {
  color: #fff;
  background-color: $greenColor;
}
.creditBtnAccept:hover {
  border: 1px solid $greenColor;
  color: $greenColor;
}
.creditBtnReject {
  color: #fff;
  background-color: $orangeColor;
  margin-left: 0.5rem;
}
.creditBtnReject:hover {
  border: 1px solid $orangeColor;
  color: $orangeColor;
}
.creditContainer .ant-card-hoverable:hover {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0);
}

.onboarding_info_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.onboarding_info_col {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
}
.onboarding_section {
  width: 30%;
  display: flex;
  flex-direction: column;
}

.onboarding_mr {
  margin-left: 1.5rem;
}

.onboarding_filter {
  display: flex;
}
.onboarding_filter_select .ant-select-selector {
  width: 7rem !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #f5f8ff !important;
  color: $mainColor !important;
}

// filter component
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 25px;
}
.editLevel.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 25px;
}
.editLevel.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 25px;
}

@media (max-width: 700px) {
  .creditContainer .ant-card-bordered {
    height: unset;
  }
}
@media (max-width: 600px) {
  .creditBtn {
    width: unset;
    height: unset;
  }
}

.btnCardsActivate {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border: 1px solid;
  border-color: unset;
}
.custom-tag {
  border-radius: 10px;
  padding: 0.1rem 0.5rem;
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.onboarding-status-pending {
  color: #fecd2f;
  border: 1px solid #fecd2f;

  background: #fff7e6;
  font-size: 12px;
  font-weight: bold;
}
.onboarding-status-rejected {
  color: #fd5394;
  border: 1px solid #fd5394;
  background: #ffe4f8;
  font-size: 12px;
  font-weight: bold;
}
.onboarding-status-approved {
  color: #20ce24;
  border: 1px solid #20ce24;
  background: #e8ffd7;
  font-size: 12px;
  font-weight: bold;
}
.approve.ant-btn-primary {
  color: $backgroundNavbar;
  background-color: $greenColor;
  margin: 0.5rem;
}
.rejectBtn.ant-btn-danger {
  color: $backgroundNavbar;
  background-color: $orangeColor;
  height: 2rem;
  width: 5.5rem;
}

.onboarding-status-pending {
  color: #fecd2f;
  border: 1px solid #fecd2f;
  background: #fff7e6;
  font-size: 12px;
  font-weight: bold;
}

.onboarding-status-approved {
  color: #20ce24;
  border: 1px solid #20ce24;
  background: #e8ffd7;
  font-size: 12px;
  font-weight: bold;
}
.approve.ant-btn-primary {
  color: $backgroundNavbar;
  background-color: $greenColor;
  margin: 0.5rem;
}
.rejectBtn.ant-btn-danger {
  color: $backgroundNavbar;
  background-color: $orangeColor;
  height: 2rem;
  width: 5.5rem;
}
.approve.ant-btn-primary[disabled],
.approve.ant-btn-primary[disabled]:hover,
.approve.ant-btn-primary[disabled]:focus,
.approve.ant-btn-primary[disabled]:active {
  color: $backgroundNavbar;
  background-color: $greenColor;
  &:focus {
    color: $backgroundNavbar;
    background-color: $greenColor !important;
    border: 1px solid transparent;
  }
  &:hover {
    color: $backgroundNavbar;
    background-color: $greenColor;
    border: 1px solid transparent;
  }
}
.declined.ant-btn-danger[disabled],
.declined.ant-btn-danger[disabled]:hover,
.declined.ant-btn-danger[disabled]:focus,
.declined.ant-btn-danger[disabled]:active {
  color: $backgroundNavbar;
  background-color: $orangeColor;
  height: 2rem;
  &:focus {
    color: $backgroundNavbar;
    background-color: $orangeColor;
    border: 1px solid transparent;
  }
  &:hover {
    color: $backgroundNavbar;
    background-color: $orangeColor;
    border: 1px solid transparent;
  }
}
