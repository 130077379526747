.btn-child {
  background: $greenDark;
  color: $backgroundNavbar;
}
.btn-active {
  background-color: $greenColor;
  color: $backgroundNavbar;
}
.btn-child:hover {
  background-color: $greenDark;
  color: $backgroundNavbar;
  border: 1px solid transparent;
}

.topHeader-1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 0.3rem;
  flex-wrap: wrap;
}
.btn-inactive {
  background-color: $orangeColor;
  color: $backgroundNavbar;
}

.btn-inactive:focus {
  background-color: $orangeColor;
  color: $backgroundNavbar;
  box-shadow: none;
  border: 1px solid transparent;
}
.btn-active:focus {
  background-color: $orangeColor;
  color: $backgroundNavbar;
  box-shadow: none;
  border: 1px solid transparent;
}
.btn-active:hover {
  border: 1px solid transparent;
  color: $backgroundNavbar;
  background-color: $greenColor;
}
.section_row{
  width: 75%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.section{
  margin: 1rem 0px;
  h3{
    color: $grayDark;
    font-weight: 700;
  }
}
.indi_content{
  width: 100px;
}
.indi_info{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  h3{
    color: $grayDark;
    font-weight: 700;
  }
 
}

.account-info {
 
  &__content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  &__image {
    width: 25%;
    border: 1px solid transparent;
    border-radius: 50%;
    height: 28%;
  }
  &__secontInput {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    // margin-right: 10px;
  }
  span {
    color: $grayLight;
  }
}
.ant-form-vertical .ant-form-item-label > label {
  color: $grayDark;
  font-weight: bold;
}
.ant-form-item-control-input {
  margin-top: -10px;
}
.indi_info_item{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
@media (max-width:750px) {
  .section_row{
    h3{
      font-size: 14px;
    }
  }
  .indi_info{
    h3{
     font-size: 14px;
    }
  }
 
 
}
@media (max-width:555px) {
 .section_row{
  flex-direction: column;
  margin:unset;
 }
 .indi_info{
  flex-direction: column;
 }
 .indi_info_item{
  margin-bottom: 2rem;
 }
 .border{
  width: 90%;
  height: 1px;
  background-color:$grayLight;
  margin: 1rem 0px;
}
}