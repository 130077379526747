@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;600;700&family=Open+Sans:wght@300;400;600&family=Roboto:wght@300&display=swap");

//main colors
$grayLight: #808191;
$grayDark: #212a54;
$iconColor: #11142d;
$backgroundNavbar: #ffffff;
$title: #171725;
// $mainColor: #0032cb;
$mainColor: #0e4bfb;

$greenColor: #20ce24;
$orangeColor: #fd5394;
$greenDark: #20ce24ff;

//fonts

$Sans: "Open Sans", sans-serif;
//font awesome
@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?91qpby");
  src: url("fonts/icomoon.eot?91qpby#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?91qpby") format("truetype"),
    url("fonts/icomoon.woff?91qpby") format("woff"),
    url("fonts/icomoon.svg?91qpby#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-TransactionFeesw:before {
  content: "\e903";
  color: #fff;
  margin-right: 0.4rem;
}
.icon-Adminw:before {
  content: "\e904";
  color: #fff;
  margin-right: 0.4rem;
}
.icon-Withdrawal-List:before {
  content: "\e90d";
  color: #212a54;
}
.icon-list:before {
  content: "\e905";
}
.icon-Insights:before {
  content: "\e906";
  color: #212a54;
  margin-right: 0.4rem;
}
.icon-Events:before {
  content: "\e907";
  color: #ffffff;
  margin-right: 0.4rem;
}
.icon-Individual-User:before {
  content: "\e908";
  color: #212a54;
}
.icon-Offer-List:before {
  content: "\e909";
  color: #212a54;
}
.icon-Notification:before {
  content: "\e90a";
  color: #212a54;
}
.icon-Withdrawal-Type:before {
  content: "\e90b";
  color: #212a54;
}
.icon-Content:before {
  content: "\e90c";
  color: #212a54;
}
.icon-Dashboard:before {
  content: "\e900";
  color: #212a54;
}
.icon-Business-Users:before {
  content: "\e901";
  color: #212a54;
}
.icon-Action-Icon1:before {
  content: "\e902";
}
