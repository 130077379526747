.site-card-wrapper {
  padding: 30px;
  background: #ececec;
}

.ant-card-head {
  border-bottom: 0px;
}

.ant-card-head-title {
  font-size: 1.25rem;
  padding-bottom: 0;
  font-weight: bold;
  color: $title;
}

.ant-card-body {
  font-size: 18px;

  color: #1e1e1e;
  font-weight: bold;
  padding: 15px 24px;
}
.ant-card-bordered {
  border-radius: 20px;
}
