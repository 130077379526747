.notificationFrom {
  padding: 2rem 5rem;
  .ant-form-item-label {
    margin-bottom: 0.6rem;
  }
  @media screen and (max-width: 460px) {
    padding: 0;
  }
  // .ant-input {
  //   margin-top: 0.6rem;
  //   width: 70%;
  //   height: 3rem;
  // }
  .add-btn {
    width: 100%;

    border-radius: 25px;
    margin-top: 1rem;
  }
}
@media only screen and (max-width: 412px) {
  .notificationFrom {
    .add-btn {
      width: 100%;
    }
  }
}
.noNotify {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  height: 30rem;
  color: #27272e;
  text-align: center;
}
.notifyModal {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 15px;
  align-items: center;
  border: 1px solid gainsboro;
}
.notifyContentContainer {
  width: 70%;
  h4 {
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
  }
  display: flex;
  justify-content: space-between;
}
.notifyTitle {
  width: 50%;
}
.notifyBody {
  width: 50%;
}
.notifyImgContainer {
  width: 30%;
  img {
    width: 100%;
    max-width: 50px;
  }
}
@media (max-width: 750px) {
  .notifyModal {
    flex-direction: column;
  }
  .notifyContentContainer {
    width: unset;
    flex-direction: column;
    text-align: center;
  }
  .notifyImgContainer {
    width: unset;
  }
  .notifyTitle {
    width: 100%;
    margin-bottom: 1rem;
  }
  .notifyBody {
    width: 100%;
  }
}
