.navbar {
  background-color: #f5f5f5;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
@media (min-width: 992px) {
  .menu {
    display: none;
  }
}
@media (max-width: 992px) {
  .dipImg {
    display: none;
  }
}
.menu {
  background: #0032cb;
}
.logo {
  height: 50px;
}
.list {
  display: flex;
  justify-content: center;
  &__image {
    margin-right: 0.4rem;
    .ant-avatar-image {
      width: 3rem;
      height: 3rem;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    &__user {
      color: $grayDark;
      font-weight: bold;
    }
    &__role {
      color: $grayLight;
      font-size: 0.9rem;
    }
  }
}
.textEnc {
  display: flex;
  flex-direction: column;
  // max-width: 180px;
  margin-left: 1.5rem;
}
.textEnc .span1 {
  color: black;
  font-size: medium;
  font-weight: 600;
}
.textEnc .span2 {
  color: $grayLight;
  font-size: medium;
  font-weight: 200;
  text-align: center;
}
.changeSize {
  display: none;
}
@media (max-width: 700px) {
  // margin-top: -2rem;
  // margin-left: 10rem;
  .navbar {
    flex-wrap: unset;
  }
  .ant-btn-icon-only {
    min-width: 32px;
  }
  .defaultSize {
    display: none;
  }
  .changeSize {
    display: block;
  }
  .textEnc {
    display: flex;
    flex-direction: column;
    max-width: 180px;
    margin-left: 1.5rem;
  }
  .textEnc .span1 {
    color: black;
    font-size: smaller;
    font-weight: 600;
  }
  .textEnc .span2 {
    color: $grayLight;
    font-size: smaller;
    font-weight: 200;
    text-align: left;
  }

  .list {
    display: grid;
    align-items: center;
    &__content {
      &__user {
        font-size: smaller;
        text-align: center;
      }
      &__role {
        font-size: smaller;
        text-align: center;
      }
      // display: none;
      order: 2;
    }
  }
  .ant-dropdown-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.dropdown__menu {
  padding: 0.4rem;
  &__row:hover {
    background-color: $greenColor;
    color: $backgroundNavbar;

    a:hover {
      color: $backgroundNavbar;
      &__icon {
        color: $backgroundNavbar;
        margin-right: 0.5rem;
      }
    }
  }
}
